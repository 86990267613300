import Loadable from "react-loadable";
import LoadingComponent from "../../commons/Loader";

export default [
  {
    path: "/faqs",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("."),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/about-myentrego",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/aboutMyEntrego"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/account",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/account"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/fees-and-payment",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/feesAndPayment"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/packaging",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/packaging"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/pickup",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/pickup"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/bulk-order-upload",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/bulkOrderUpload"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/delivery",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/delivery"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/rts",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/returnToSender"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/cash-on-delivery",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/cashOnDelivery"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/shipping-documents",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/shippingDocuments"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/faqs/insurance-and-claims",
    exact: true,
    authL: false,
    component: Loadable({
      loader: () => import("./pages/insuranceAndClaims"),
      loading: LoadingComponent,
    }),
  },
];
